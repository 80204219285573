import { ELanguage } from '@core/type';

export const addParameters = (url: string, parameters: Record<string, string>) => {
  if (Object.keys(parameters).length !== 0) {
    const searchParams = new URLSearchParams(parameters);
    return searchParams ? `${url}?${searchParams.toString()}` : url;
  }
  return url;
};

export const getFullPathURL = (
  locationOrigin: string,
  language: ELanguage,
  href: string,
): string => {
  return `${locationOrigin}/${language}${href}`;
};
