import styled, { css, CSSObject } from 'styled-components';

import { EColor, WithThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { InputBaseProps } from './interface-input';

export const InputBase = styled.input<InputBaseProps>((props: InputBaseProps & WithThemeProps) => {
  const {
    theme: {
      palette,
      structure: {
        input: {
          base: { active: activeRules, hidden: hiddenRules, ...rules },
        },
      },
    },
    hidden,
    textAlign,
    fontSize,
  } = props;
  const { getTextColor, getBackgroundColor } = getPaletteHandlers(palette);
  const textPrimary = getTextColor(EColor.PRIMARY);

  /* stylelint-disable property-no-vendor-prefix, no-descending-specificity */
  return css`
    ${rules as CSSObject};
    ${hidden && hiddenRules};
    color: ${textPrimary};
    text-align: ${textAlign};
    background-color: transparent;
    font-size: ${fontSize ?? '16px'};

    &:focus:not(:disabled, :read-only),
    &:active:not(:disabled, :read-only) {
      ${activeRules}
    }

    &::placeholder {
      color: ${getTextColor(EColor.SECONDARY)};
    }

    &:disabled,
    &:read-only {
      cursor: default;
      color: ${textPrimary};
    }

    &:disabled {
      background-color: ${getBackgroundColor({ commonType: EColor.GRAY, intensity: EColor.A50 })};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${textPrimary};
      -webkit-box-shadow: 0 0 0 100vw
        ${getBackgroundColor({
          semanticType: EColor.NEUTRAL,
          variant: EColor.LIGHT,
        })}
        inset !important;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `;
  /* stylelint-enable property-no-vendor-prefix */
});
